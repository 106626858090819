// ProjectsSection.js

import React, {useMemo} from "react";
import FullScreenSection from "./FullScreenSection";
import {Heading} from "@chakra-ui/react";
import Card from "./Card";
import {useAppContext} from "context/AppContext";
import FilterBar from "./FilterBar";
import Grid from "./Grid";
import {useUrlParam} from "hooks/useUrlParam";
import {useFilteredProjects, useTechOptions} from "hooks/useProjectFilters";
import {goToSection} from "services/helpers";


const ProjectsSection = () => {
	// const {status, data = {projects: []}, error} = useAppContext();
	const {data = {projects: []}} = useAppContext();

	const [page, setPage] = useUrlParam('p', 0);
	const [textFilter, setTextFilter] = useUrlParam('search');
	const [techFilter, setTechFilter] = useUrlParam('tech');


	const allTechStackOpts = useTechOptions(data.projects);

	const filterVals = useMemo(() => {
		const selTechStack = techFilter ? techFilter.split(',').map(val => {
			return {
				value: val,
				label: allTechStackOpts.find(tech => tech.value === val).label
			};
		}) : [];

		return {
			selText: textFilter,
			selTechStack,
		};
	}, [textFilter, techFilter, allTechStackOpts]);

	const projectsFiltered = useFilteredProjects(data.projects, filterVals);

	const handlePaginationClick = (newPage) => {
		setPage(newPage);
		goToSection('projects');
	}

	const onFilter = ({selText, selTechStack}) => {
		if (selText !== undefined) {
			setTextFilter(selText);
			setPage(0);
		}
		if (Array.isArray(selTechStack)) {
			const techValsStr = selTechStack.map(tech => tech.value).join(',');
			setPage(0);
			setTechFilter(techValsStr);
		}
	};

	return (
		<FullScreenSection
			id="projects-section"
			// backgroundColor="#14532d"
			isDarkBackground
			alignItems="center"
			justifyContent="start"
			spacing={{base: 6, md: 6, lg: 8}}
			width="100%"
			paddingTop={{base: '65px', md: '65px', lg: '65px'}}
			paddingBottom={{base: '65px', md: '65px', lg: '100px'}}
		>
			<Heading as="h1" 
				// scrollMarginTop="65px"
				marginBottom={{base: '00px', md: '20px', lg: '35px'}}>Projects</Heading>

			<FilterBar onFilter={onFilter} filterVals={filterVals} dropdownOptions={allTechStackOpts} />

			<Grid perPage={6} page={page} items={projectsFiltered} onPageClick={handlePaginationClick}>
				{project => (
					<Card
						key={project.id}
						title={project.title_rendered}
						subheading={project.acf.subheading}
						description={project.content_raw}
						coverImg={project.featured_media}
						youtubeVideoId={project.acf.youtube_video_id}
						technologies={project.acf.technologies}
						link={project.acf.link}
					/>
				)}
			</Grid>
		</FullScreenSection>
	);
};

export default ProjectsSection;
