import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'

import {useModalContext} from 'context/ModalContext'


function ModalYoutube() {

	const {isOpen, onClose, title, content} = useModalContext();

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay bg={'rgba(0, 0, 0, 0.55)'}/>
			<ModalContent width={{base: 'calc(100% - 40px)', md: '140vmin', lg: '140vmin'}} maxW={'calc(100% - 40px)'} backgroundColor={'transparent'}>
				
				{/* {title && <ModalHeader>{title}</ModalHeader>} */}

				{/* <ModalCloseButton style={{top: '-20px', right: '-40px', width: 'auto', height: 'auto', color: '#fff', opacity: 0.4, fontSize: '25px'}}/> */}

				{content && <ModalBody padding={0} overflow={'hidden'} borderRadius={'var(--chakra-radii-md)'}>
					<div className='iframe-container'>
						<iframe width="560" height="315" src={`https://www.youtube.com/embed/${content}?disablekb=1&fs=0&loop=1&rel=0&showinfo=0&autoplay=1&vq=hd1080`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
					</div>
				</ModalBody>}

				{/* <ModalFooter>
					<Button colorScheme='blue' mr={3} onClick={onClose}>
						Close
					</Button>
					<Button variant='ghost'>Secondary Action</Button>
				</ModalFooter> */}
			</ModalContent>
		</Modal>
	)
}

export default ModalYoutube