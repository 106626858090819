import {useState, useEffect} from 'react';



function requestAnimFrame(callback) {
	let ticking = false;
	return function (...args) {
		if (!ticking) {
			ticking = true;
			window.requestAnimationFrame(() => {
				callback.apply(this, args);
				ticking = false;
			});
		}
	}
}


export default function useWindowScroll() {
	const [direction, setDirection] = useState('');

	useEffect(() => {
		let prevScrollY = null;

		const handleScroll = requestAnimFrame(() => {
			const scrollY = window.scrollY;

			if (!prevScrollY) {
				prevScrollY = scrollY;
				return;
			}

			if (prevScrollY < scrollY) {
				setDirection('down');
			} else if (prevScrollY > scrollY) {
				setDirection('up');
			}
			prevScrollY = scrollY;
		});

		window.addEventListener('scroll', handleScroll);

		return () => {
			prevScrollY = null;
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return {direction};
}
