export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const simulateNetworkConditions = async (delayT, failRate) => {
	if (delayT) await delay(delayT);
	if (failRate >= Math.random()) throw new Error('Forced fail');
};

export function toErrorInstance(e) {
	if (e instanceof Error) {
		return e;
	}

	// This part here is for dealing with unhandled errors that are not instances of Error
	let message = 'An unknown error occurred';
	if (typeof e === 'string') {
		message = e;
	} else if (typeof e === 'number') {
		message = `Error code: ${e}`;
	} else if (typeof e === 'object' && e !== null) {
		if (e.message) {
			message = e.message;
		} else {
			try {
				message = JSON.stringify(e);
			} catch (jsonError) {
				message = 'An error occurred, but it could not be stringified';
			}
		}
	}
	return new Error(message);
}

export function debounce(callback, delay) {
	let timer = null;
	return function(...args) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			callback.apply(this, args);
		}, delay);
	}
}

export function throttle(callback, limit) {
	let timer = null;
	return function(...args) {
		if (!timer) {
			callback.apply(this, args);
			timer = setTimeout(() => {
				timer = null;
			}, limit);
		}
	}
}

export const getUniqueTechnologies = (projects) => {

	// console.log(projects);

	const techSet = new Set();
	const uniqueTechnologies = [];

	projects.forEach(project => {
		project.acf.technologies.forEach(tech => {
			if (!techSet.has(tech.value)) {
				techSet.add(tech.value);
				uniqueTechnologies.push(tech);
			}
		})
	});

	// Sort unique technologies by the label property
	uniqueTechnologies.sort((a, b) => a.label.localeCompare(b.label));

	return uniqueTechnologies;
}

export const isNumber = (str) => {
	return str !== '' && !isNaN(parseFloat(str)) && isFinite(str);
};

export const getValue = (val) => {
	return isNumber(val) ? Number(val) : val;
}

export const scrollToSection = (anchor) => {
	const id = `${anchor}-section`;
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	}
}

// If the window has passed the section, will add an offset of "diff" setting it slightly above the section, then is scrolls down to the right position
// This is to avoid showing the menu when scrolling up to the section

export const goToSection = (anchor) => {
	const id = `${anchor}-section`;
	const element = document.getElementById(id);
	if (element) {

		const diff = 1;
		const computedStyle = window.getComputedStyle(element);
		const scrollMarginTop = parseInt(computedStyle.getPropertyValue('scroll-margin-top')) || 0;
		const elementTop = element.getBoundingClientRect().top + window.pageYOffset - scrollMarginTop;

		let timer = null;
		const onScroll = () => {

			// console.log(window.pageYOffset, elementTop, window.pageYOffset - elementTop);

			if (Math.abs(window.pageYOffset - elementTop) > diff) {
				clearTimeout(timer);
				timer = setTimeout(() => {
					element.scrollIntoView({
						behavior: "auto",
						block: "start",
					});
				}, 0)
				window.removeEventListener('scroll', onScroll);
			}
		};

		// Add scroll event listener
		window.addEventListener('scroll', onScroll);


		const passedElement = window.pageYOffset - elementTop > diff;

		// Scroll the window to the position of the element
		window.scrollTo({
			top: elementTop - (passedElement ? diff : 0),
			left: 0,
			behavior: "auto", // Instant scroll without animation
		});
	}
};