import {createContext, useContext, useState} from "react";


const ModalContext = createContext();


export function ModalProvider({children}) {

	const [state, setState] = useState({
		isOpen: false,
		title: '',
		content: '',
	});

	return (
		<ModalContext.Provider value={{
			...state,
			onOpen: (title, content) => setState({isOpen: true, title, content}),
			onClose: () => setState({isOpen: false, title: '', content: ''}),
		}}>
			{children}
		</ModalContext.Provider>
	)
}

export function useModalContext() {
	const context = useContext(ModalContext);
	if (!context) {
		throw new Error('useModalContext must be used within ModalProvider');
	}
	return context;
}