import {useModalContext} from "context/ModalContext";

// Wrapper for Modal Context specifically for youtube
export function useModalYoutube() {
	const {onOpen: onOpenOriginal, ...rest} = useModalContext();

	function onOpen(youtubeId) {
		onOpenOriginal('', youtubeId);
	}

	return {onOpen, ...rest}
}