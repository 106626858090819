import React from 'react';
import {Button, HStack} from "@chakra-ui/react";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {j} from "@fortawesome/free-solid-svg-icons";
// import {} from "@fortawesome/fontawesome-svg-core";
import {FaForwardStep, FaBackwardStep, FaForward, FaBackward} from "react-icons/fa6";



// Utility function to generate the range of page numbers
function generateRange(current = 0, length = 3, min = 0, max = 1000) {
	const half = Math.floor(length / 2);
	let start = Math.max(current - half, min);
	let end = start + (length - 1);

	if (end > max) {
		end = max;
		start = Math.max(end - (length - 1), min);
	}

	return Array.from({length: end - start + 1}, (_, i) => start + i);
}

// Custom hook for pagination logic
function usePagination(current, total, length = 3) {
	const pageNumbers = generateRange(current, length, 0, total - 1);
	const showFirstButton = current > 0;
	const showLastButton = current < total - 1;
	// const showFirstButton = pageNumbers[0] > 0;
	// const showLastButton = pageNumbers[pageNumbers.length - 1] < total - 1;

	const showPrevious = current > 0;
	const showNext = current < total - 1;

	return {showFirstButton, showLastButton, showPrevious, showNext, pageNumbers};
}

// Pagination component
export const Pagination = ({total, current = 0, visiblePages = 3, prevNextOnly = false, onClick, ...rest}) => {

	const {showFirstButton, showLastButton, showPrevious, showNext, pageNumbers} = usePagination(current, total, visiblePages);

	if (!total || total <= 1) return null;

	// Event handler for page button clicks
	const handleClick = (page) => () => {
		if (onClick) {
			onClick(page);
		}
	};

	// Accessibility: ARIA labels for pagination navigation
	return (
		<nav aria-label="Pagination Navigation" className="pagination" >
			<HStack spacing={4} justifyContent='center' {...rest}>

				{prevNextOnly ? (
					<>
						<Button size='sm' isDisabled={!showPrevious} style={{...(!showPrevious && {cursor: 'default'})}} onClick={handleClick(current - 1)} aria-label="Go to previous page">
							<FaBackward />
						</Button>

						<Button size='sm' isDisabled={!showNext} style={{...(!showNext && {cursor: 'default'})}} onClick={handleClick(current + 1)} aria-label="Go to next page">
							<FaForward />
						</Button>
					</>
				) : (
					<>
						<Button size='sm' isDisabled={!showFirstButton} style={{...(!showFirstButton && {cursor: 'default'})}} onClick={handleClick(0)} aria-label="Go to first page">
							<FaBackwardStep />
						</Button>

						{pageNumbers.map((page) => (
							<Button
								key={page}
								className={`item${page === current ? ' active' : ''}`}
								onClick={handleClick(page)}
								aria-current={page === current ? 'page' : undefined}
								aria-label={`Go to page ${page + 1}`}
							>
								{page + 1}
							</Button>
						))}

						<Button size='sm' isDisabled={!showLastButton} style={{...(!showLastButton && {cursor: 'default'})}} onClick={handleClick(total - 1)} aria-label="Go to last page">
							<FaForwardStep />
						</Button>
					</>
				)
				}

			</HStack >
		</nav >
	);
};
