import {useRef, useState} from "react";
import {Heading, Image, Text, VStack, AspectRatio, Link, Button, HStack, Flex, Tag, Box} from "@chakra-ui/react";
// import {FaYoutube, FaCirclePlay, FaRegCirclePlay} from "react-icons/fa6";
import {ExternalLink, TvMinimalPlay} from 'lucide-react';

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useDetectOverflow from "hooks/useDetectOverflow";
import HtmlContent from "./HtmlContent";
import {useModalYoutube} from "hooks/useModalYoutube";
import {useHorizontalScroll} from "hooks/useHorizontalScroll";


// import {
// 	Modal,
// 	ModalOverlay,
// 	ModalContent,
// 	ModalHeader,
// 	ModalFooter,
// 	ModalBody,
// 	ModalCloseButton,
// } from '@chakra-ui/react'


// const MotionVStack = motion(VStack);

const Card = ({style, title, subheading, description, coverImg, technologies, link, youtubeVideoId}) => {

	const ref = useRef(null);
	const descHasOverflow = useDetectOverflow(ref, 300);

	// const imageLink = youtubeVideoId ? `https://www.youtube.com/watch?v=${youtubeVideoId}` : link;
	// const videoLink = youtubeVideoId ? `https://www.youtube.com/watch?v=${youtubeVideoId}` : null;
	// eslint-disable-next-line no-undef
	const imageSrc = coverImg ? coverImg : (youtubeVideoId ? `https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg` : require('assets/imgs/placeholder-image.png'));

	const {onOpen} = useModalYoutube();

	const scrollContainerRef = useHorizontalScroll({
		sensitivity: 0.24,  // Adjust for faster/slower scrolling
		maxSpeed: 16,       // Maximum scroll speed
	});

	// const [isOpen, setIsOpen] = useState(false);

	// console.log(style);

	return (
		<VStack
			className="card"
			style={style}
			borderRadius='10px'
			background="rgb(28,37,52)"
			backgroundImage="linear-gradient(180deg, rgba(28,37,52,1) 0%, rgba(20,28,43,1) 100%)"
			border="1px solid rgb(55 65 81)"
			color='rgba(255,255,255,0.98)'
			textAlign='left'
			spacing={0}

			// flex='1 0 0%'
			// flex='0 0 auto'
			// width='33.33%'
			// maxWidth='100%'

			// (100% - ((n-1) * gap)) / n
			width='calc((100% - (var(--items-per-row) - 1) * var(--gap)) / var(--items-per-row))'

			_hover={{
				shadow: '0 2px 12px 1px rgba(0, 0, 0, 0.3)',
				// 'img': {transform: 'scale(0.9)'}
			}}
		// transition='all 0.4s ease-out'
		// initial={{opacity: 0, scale: 0.95}}
		// animate={{opacity: 1, scale: 1}}
		// transition={{duration: 0.4, ease: "easeInOut"}}
		>
			<AspectRatio w="100%" ratio={16 / 9}>
				{imageSrc && (
					// imageLink ? (
					//   <Link href={imageLink} target='_blank' rel="noopener noreferrer" borderTopLeftRadius='10px' borderTopRightRadius='10px'>
					//     <Image src={imageSrc} alt={title} objectFit='cover' />
					//   </Link>
					// ) : (
					<Image src={imageSrc} alt={title} objectFit='cover' borderTopLeftRadius='10px' borderTopRightRadius='10px' />
					// )
				)}
			</AspectRatio>

			<VStack p='20px' spacing='15px' align='flex-start' borderTop='1px solid rgb(55 65 81)'
				width='100%'
			// flex='1 0 0%'
			>

				<Heading textAlign='left' size='md' >
					{/* <span dangerouslySetInnerHTML={{__html: title}} /> */}
					<HtmlContent as='span' html={title} />
				</Heading>

				<Heading textAlign='left' size='xs' fontWeight={600} color=''>{subheading}</Heading>

				<Box className='tech-stack' w='100%'
					ref={scrollContainerRef}
					overflowX="auto"
					css={{
						'&::-webkit-scrollbar': {display: 'none'},
						msOverflowStyle: 'none',
						scrollbarWidth: 'none',
					}}>
					<Flex gap={1} w='100%' >
						{technologies.map((tech, idx) => {
							return (
								<Tag key={idx} fontSize={13} size={'sm'} whiteSpace={'nowrap'} flex='0 0 auto' cursor={'pointer'}>{tech.label}</Tag>
							)
						})}
					</Flex>
				</Box>

				{/* <Text fontSize='14px' marginTop={-3}>
					{technologies.map((tech, idx) => {
						return (
							<span key={idx}>
								<span style={{}}>{tech.label}</span>
								{idx !== technologies.length - 1 ? <>, </> : null}
							</span>
						)
					})}
				</Text> */}

				<Text ref={ref}
					color='rgb(209 213 219)'
					fontSize='15px'
					height='135px'
					overflow='auto'
					whiteSpace='break-spaces'
					style={{paddingRight: descHasOverflow ? '15px' : '0'}}>
					<HtmlContent as='span' html={description} />
				</Text>

				{/* <Text color='#677489' fontSize='15px' marginTop='auto'> */}
				{/* {link && <a textDecoration='underline' href={link} target='_blank' rel="noopener noreferrer">Project</a>} */}
				{/* {videoLink && <> {link && <>|</>} <a textDecoration='underline' href={videoLink} target='_blank' rel="noopener noreferrer">Video</a></>} */}
				{/* {youtubeVideoId && <> {link && <>|</>} <Link textDecoration='underline' onClick={() => onOpen(youtubeVideoId)}>Video</Link></>} */}
				{/* </Text> */}

				<Flex className='links' gap={4} w='full' mt={1} direction={{base: 'column', sm: 'row'}}>
					{link && <Button size='md' fontSize={14} flex={{sm: 1}} display='inline-flex' gap={2} as="a" href={link} target='_blank' rel="noopener noreferrer">
						Project
						<ExternalLink className="icon" size={14} />
					</Button>}
					{youtubeVideoId && <Button size='md' fontSize={14} flex={{sm: 1}} display='inline-flex' gap={2} onClick={() => onOpen(youtubeVideoId)}>
						Snapshot
						<TvMinimalPlay className="icon" size={14} />
					</Button>}
				</Flex>

				{/* <Text>See more <FontAwesomeIcon icon={faArrowRight} size="1x" /></Text> */}

				{/* {youtubeVideoId &&
					<div className="video-container" data-id={youtubeVideoId} onClick={() => onOpen(youtubeVideoId)}>
						<div className="iframe-container">
							<div className="video-iframe"></div>
						</div>
						<div className="video-play">
							<img src={`https://img.youtube.com/vi/${youtubeVideoId}/sddefault.jpg`} alt="" />
							<div className="icon">
								<FaRegCirclePlay size={50} color="#eee" />
							</div>
						</div>
					</div>
				} */}

			</VStack>
		</VStack >
	);
	// return null;
};

export default Card;
