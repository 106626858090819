// theme.js
import {background, border, extendTheme} from "@chakra-ui/react";

/*
	Colors:
		from lighter to darker

	rgb(55, 65, 81)		light blue gray
	rgb(31, 41, 55)
	rgb(28, 37, 52)		darker blue gray
	rgb(20, 28, 43)		dark blue
	rgb(3, 7, 18)			very dark almost black

*/

const theme = extendTheme({
	styles: {
		global: {
			html: {
				// overflow: "overlay",
				background: "rgb(3,7,18)",
				backgroundImage: "linear-gradient(180deg, rgb(3,7,18) 0%, rgb(31,41,55) 100%)",
			},
			body: {
				background: "inherit"
				// bg: '#1f2937', // or use color tokens like 'gray.100'
				// bg: '#ddd', // or use color tokens like 'gray.100'
				// bg: '#fff', // or use color tokens like 'gray.100'
			},
		}
	},
	fonts: {
		body: "'Geist', serif",
		heading: "'Geist', serif",
	},
	components: {
		// ModalOverlay: {
		// 	baseStyle: {
		// 		background: 
		// 	}
		// },
		Input: {
			// baseStyle: {
			// 	field: {
			// 		fontSize: '15px',
			// 		// borderColor: "red",
			// 		border: "2px solid red",
			// 		_focus: {
			// 			borderColor: "blue.500"
			// 		},
			// 		_placeholder: {
			// 			color: '#fff', // Change this to your desired color
			// 		},
			// 		// _focusVisible: {
			// 		// 	border: '1px solid #fff !important',
			// 		// 	boxShadow: '0 0 0 1px #fff !important',
			// 		// 	zIndex: 1,
			// 		// },
			// 	},
			// },
			variants: {
				custom: {  // new variant name
					field: {
						fontSize: '15px',
						border: "1px solid #B7B9BC",
						background: 'transparent',
						_focusVisible: {
							// borderColor: "#fff",
							boxShadow: '0 0 7px 0px #ffffff59'
							// boxShadow: 'none'
						},
						_placeholder: {
							color: '#fff',
						},
					}
				}
			},
			defaultProps: {
				variant: 'custom'
			}
		},
	},
});

export default theme;
