import React, {useEffect} from "react";
import {Github, Linkedin} from 'lucide-react';

// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
// import {
  // faGithub,
  // faLinkedin,
  // faMedium,
  // faStackOverflow,
// } from "@fortawesome/free-brands-svg-icons";

import {Box, HStack, Link} from "@chakra-ui/react";
import useWindowScroll from "../hooks/useWindowScroll";
import {scrollToSection} from "services/helpers";
import Menu from "./Menu";


const socials = [
  // {
  //   icon: faEnvelope,
  //   url: "mailto: hello@example.com",
  // },
  {
    // icon: faGithub,
    icon: Github,
    url: "https://github.com/ldoliv",
  },
  {
    // icon: faLinkedin,
    icon: Linkedin,
    url: "https://www.linkedin.com/in/leonel-oliveira-49979b91/",
  },
  // {
  //   icon: faMedium,
  //   url: "https://medium.com",
  // },
  // {
  //   icon: faStackOverflow,
  //   url: "https://stackoverflow.com",
  // },
];



const Header = () => {

  const {direction} = useWindowScroll();
  const transform = direction === 'down' ? 'translateY(-200px)' : 'translateY(0)';

  const handleClick = (anchor) => {
    return () => {
      scrollToSection(anchor);
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      scrollToSection(hash.slice(1));
    }
  }, [])



  return (
    <Box
      as='header'
      position="fixed"
      top={0}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      backgroundColor="rgba(3,7,18,1)"
      zIndex={1}
      transform={transform}
    >
      <Box className='' color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={{base: 8, md: 8, lg: 8}}
          py={{base: 3, md: 4, lg: 4}}
          justifyContent="space-between"
          alignItems="center"
        >

          {/* Add social media links based on the `socials` data */}
          <HStack
            as="nav"      // 👈 can be set as "<nav>" tag
            className="socials"
            spacing={6}
          >
            {socials.map((social, idx) => {
              return (
                <Link key={idx} href={social.url} target='_blank' rel="noopener noreferrer" _hover={{textDecoration: "none"}} >
                  {/* <FontAwesomeIcon icon={social.icon} size="2x" /> */}
                  <social.icon size={25} />
                </Link>
              )
            })}
          </HStack>

          <Menu breakpoints={{
            'tablet': 480,			// winWidth >= 480 && winWidth < 1200
            'desktop': 1200,		// winWidth >= 1200
          }}>
            <Link href='#about' onClick={handleClick('about')} _hover={{textDecoration: "none"}}>About</Link>
            <Link href='#projects' onClick={handleClick('projects')} _hover={{textDecoration: "none"}}>Projects</Link>
          </Menu>
          
          {/* <HStack
            as="nav"
            spacing={8}>
            <Link href='#about' onClick={handleClick('about')} _hover={{textDecoration: "none"}}>About</Link>
            <Link href='#projects' onClick={handleClick('projects')} _hover={{textDecoration: "none"}}>Projects</Link> */}
            {/* <Link href='#contact-me' onClick={handleClick('contactme')} _hover={{textDecoration: "none"}}>Contact Me</Link> */}
          {/* </HStack> */}

        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
