// import {delay} from "../helpers";
import {publicRequest} from "./axios";
// import {useAxiosPrivate} from "./useAxiosPrivate";



export function useAxios() {

	// const privateRequest = useAxiosPrivate();

	return {
		getAllData: async () => {
			return Promise.all([
				publicRequest.get('/wp/v2/pages/', {
					// signal,
					// delayT: 3000,
					// failRate: 0.5,
				}),
				// publicRequest.get('/wp/v2/projects/', {
				publicRequest.get('/wp/v2/projects/?per_page=100&image_size=large', {
				// publicRequest.get('/wp/v2/projects/?image_size=medium', {
					// signal,
					// delayT: 2000,
					// failRate: 0.5,
				}),
			]).then(response => {
				// console.log(response);
				return {
					pages: response[0],
					projects: response[1]
				}
			})
		},
		// getPages: async (signal) => {
		// 	return publicRequest.get('/wp/v2/pages', {
		// 		signal,
		// 		// delayT: 600,
		// 		// failRate: 0.5,
		// 	});
		// },
		// getProjects: async (signal) => {
		// 	return publicRequest.get('/wp/v2/projects', {
		// 		signal,
		// 		// delayT: 600,
		// 		// failRate: 0.5,
		// 	});
		// },
		// getAboutPage: async (signal) => {
		// 	return publicRequest.get('/wp/v2/pages/?slug=about-me', {
		// 		signal,
		// 		// delayT: 600,
		// 		// failRate: 0.5,
		// 	});
		// },

		// loginFake: async (user) => {
		// 	await delay(500);
		// 	const {username, password} = user;
		// 	if (username === 'leo123' && password === '123') {
		// 		return {
		// 			name: 'Leo',
		// 			username: 'leo123',
		// 			token: 'asdf65asdf654asd6f54asf',
		// 		};
		// 	}
		// 	throw new Error('User not found!');
		// },
		// refresh: async (user, signal) => {
		// 	return publicRequest.post('/refresh', {}, {
		// 		signal,
		// 		withCredentials: true
		// 	});
		// },
		// login: async (user, signal) => {
		// 	return publicRequest.post('/login', user, {
		// 		signal,
		// 		withCredentials: true		// 👈 must be set for the endpoint that creates and sets the cookie
		// 	});
		// },
		// logout: async (signal) => {
		// 	return publicRequest.post('/logout', {}, {
		// 		signal,
		// 		withCredentials: true
		// 	});
		// },
		// getTasks: async (signal) => {
		// 	return privateRequest.get('/tasks', {
		// 		signal,
		// 		delayT: 600
		// 	});
		// },
		// getTask: async (id, signal) => {
		// 	return privateRequest.get(`/tasks/${id}`, {
		// 		signal,
		// 		delayT: 500
		// 	})
		// },
		// deleteTask: async (id, signal) => {
		// 	return privateRequest.delete(`/tasks/${id}`, {
		// 		signal,
		// 		failRate: 0.3
		// 	})
		// },
		// updateTask: async (task, signal) => {
		// 	return privateRequest.put(`/tasks/${task.id}`, task, {
		// 		signal,
		// 		failRate: 0.5,
		// 		delayT: 500
		// 	})
		// },
		// addTask: async (task, signal) => {
		// 	return privateRequest.post(`/tasks`, task, {
		// 		signal,
		// 		delayT: 1000
		// 	})
		// }
	}
}
