import {useMemo} from "react";
import {getUniqueTechnologies} from "services/helpers";

export const useTechOptions = (projects) => {
	return useMemo(() => getUniqueTechnologies(projects), [projects]);
};

export const useFilteredProjects = (projects, filterVals) => {
	return useMemo(() => {
		const {selText, selTechStack} = filterVals;

		return projects.filter(project => {
			const {acf, content_raw, title_rendered} = project;
			let found = true;

			if (selText) {
				const selTextLower = selText.toLowerCase();
				const inTitle = title_rendered.toLowerCase().includes(selTextLower);
				const inContent = content_raw.toLowerCase().includes(selTextLower);
				const technologies = acf.technologies.map(tech => tech.label.toLowerCase()).join(' ');
				const inTech = technologies.includes(selTextLower);
				// const inTech = acf.technologies.some(tech => tech.label.toLowerCase().includes(selTextLower)); // 🔥 needs testing

				found = inTitle || inContent || inTech;
			}

			if (selTechStack.length) {
				found &&= selTechStack.every(selTech => {
					return acf.technologies.find(tech => selTech.value === tech.value)
				});
			}

			return found;
		});
	}, [projects, filterVals]);
};
