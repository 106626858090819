import {useAsync} from "hooks/useAsync";
import React, {createContext, useContext, useEffect} from "react";
import {useAxios} from "services/axios/useAxios";


const AppContext = createContext();


export function AppProvider({children}) {
	const apiMethods = useAxios();
	const [response, getAllData] = useAsync(apiMethods.getAllData);

	useEffect(() => {
		getAllData();
	}, [])

	const value = response;

	return (
		<AppContext.Provider value={value}>
			{children}
		</AppContext.Provider>
	)
}

export function useAppContext() {
	const context = useContext(AppContext);
	if (!context) {
		throw new Error('useAppContext must be used within AppProvider');
	}
	return context;
}