
import {Text, Avatar, Image, Heading, VStack, Box} from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
// import {useAxios} from "services/axios/useAxios";
// import {useAsync} from "hooks/useAsync";
import {useAppContext} from "context/AppContext";
import HtmlContent from "./HtmlContent";




const LandingSection = () => {

  const response = useAppContext();
  const aboutPage = !response.error ? response.data.pages.find(page => page.slug === 'about-me') : null;

  // console.log(response);


  if (!aboutPage)
    return null;

  return (
    <FullScreenSection
      id='about-section'
      justifyContent="center"
      alignItems="center"
      isDarkBackground
      // backgroundColor="#ccc"
    >
      <VStack spacing={5}>
        <Box textAlign='center'>
          {/* <Avatar name='Leonel' style={{width: '200px', height: '200px'}} mb={3} src={aboutPage.featured_media} size='2x1' /> */}
          <Image
            borderRadius='full'
            boxSize={{base: '180px', md: '200px', lg: '200px'}}
            mb={6}
            marginLeft='auto'
            marginRight='auto'
            objectFit='cover'
            src={aboutPage.featured_media}
            alt='Leonel'
            boxShadow="0px 0px 32px rgba(0,0,0,0.4)"
          />
          <Heading as='h2' size='xl'>{aboutPage?.acf.heading}</Heading>
        </Box>
        <Box textAlign='center' maxWidth='740px'>
          <Text fontSize={['lg', 'lg', 'lg']} style={{}}>
            <HtmlContent as='span' html={aboutPage.content_rendered} />
          </Text>
        </Box>
      </VStack>
    </FullScreenSection>
  );
}

export default LandingSection;
